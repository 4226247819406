import React from "react";
import { features } from "../data/features";
import FeatureCard from "./FeatureCard";
import "../styles/Features.scss";

const Features = () => {
  return (
    <div className="featuresContainer">
      <div className="featuresTitle">What we offer</div>
      <div className="features">
        {features.map((feature) => (
          <FeatureCard key={feature.id} feature={feature} />
        ))}
      </div>
    </div>
  );
};

export default Features;
