import React from "react";
import "../../styles/About.scss";
import AboutBG from "../../assets/about_us.svg";

// const usersDetails = [
//   {
//     name: "Michael Shaulov",
//     role: "CEO, CO-FOUNDER",
//     imageURL: "https://dunham-bush.com/wp-content/uploads/2019/02/person1.jpg",
//   },
//   {
//     name: "Idan Ofrat",
//     role: "CTO, CO-FOUNDER",
//     imageURL:
//       "https://strayhornlaw.com/wp-content/uploads/2019/09/image0-1170x1170.jpeg",
//   },
//   {
//     name: "Pavel Berengoltz",
//     role: "VP R&D, CO-FOUNDER",
//     imageURL: "https://raajratna.com/wp-content/uploads/2019/01/person5.jpg",
//   },
// ];

// const showUserDetails = ({ name, role, imageURL }) => {
//   return (
//     <div key={name} className="userDetails">
//       <img src={imageURL} alt="img" />
//       <div className="userName">{name}</div>
//       <div className="userRole">{role}</div>
//     </div>
//   );
// };

const About = () => {
  return (
    <div className="aboutPage">
      <div className="aboutLeftContainer">
        <div className="title">About ENTER AI Tech</div>
        <div className="p1">
          <b>ENTER AI Tech</b> - We cooperate with businesses of all sizes, from
          startups to large enterprises. Our research tells us that, regardless
          of the size of a company, business owners face similar challenges when
          planning new data initiatives. With us, you’ll save time on looking
          for top-notch specialists. Our Data scientists, AI consultants,
          Advisors, and ML engineers will bring technical know-how to your
          project from day one.
        </div>
        <div className="p2">
          <h1>Contact details</h1>
          <ul>
            <li>Phone: 70755 85837</li>
            <li>Mobile: 97044 91986</li>
            <li>Email: admin@enterai.in</li>
            <li>
              <a
                href="https://www.linkedin.com/company/enter-ai/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="aboutRightContainer">
        {/* <div className="col1">
          {usersDetails
            .slice(0, 2)
            .map((userDetails) => showUserDetails(userDetails))}
        </div>
        <div className="col2">
          {usersDetails
            .slice(2, 3)
            .map((userDetails) => showUserDetails(userDetails))}
        </div> */}
        <img src={AboutBG} alt="about" />
      </div>
    </div>
  );
};

export default About;
