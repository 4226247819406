import { Button } from "@mui/material";
import React, { useRef } from "react";
import Typewriter from "typewriter-effect";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeatureCard from "../../components/FeatureCard";
import ContactUsForm from "../../components/ContactUsForm";
import colors from "../../styles/_colors.scss";
import "../../styles/TechLanding.scss";

const features = [
  {
    id: 1,
    title: "Big Data Visualization",
    subHeading:
      "Extract valuable insights from unstructured data from multiple sources.",
  },
  {
    id: 2,
    title: "Computer Vision",
    subHeading:
      "Extract more insights from visual data, and detect and classify images according to their features into topic groups.",
  },
  {
    id: 3,
    title: "Predictive Analytics",
    subHeading:
      "Anticipate trends, sales and costs, reveal anomalies to mitigate risks and steer your business in the right direction.",
  },
  {
    id: 4,
    title: "NLP (Natural Language Processing)",
    subHeading:
      "Extract meaning from text data and speed up the reporting and analysis processes.",
  },
];

const services = [
  {
    id: 1,
    title: "Deep Learning Solutions Development",
    subHeading:
      "We provide our clients with quality assistance to streamline business processes and significantly improve business metrics by fine-tuning the performance of algorithms powered by artificial neural networks.",
  },
  {
    id: 2,
    title: "Custom Web Application Development",
    subHeading:
      "Our customers get expert assistance in matters related to custom machine learning-based web development services to enhance their business efficiency and gain results unseen before.",
  },
  {
    id: 3,
    title: "Enterprise Machine Learning as a Service",
    subHeading:
      "Our hand-picked team builds machine learning solutions for enterprises to help business owners automate and optimize business operations through deriving valuable insights from raw data with human intervention eliminated.",
  },
];

const consultingServices = [
  {
    id: 1,
    title: "Unveiling Opportunities for Data Collection",
    subHeading:
      "We're aware of the data challenges that organizations face like data collection and storage for further analysis. We help companies pull together the requirements for data collection, identify issues, and uncover relevant opportunities.",
  },
  {
    id: 2,
    title: "Conducting Exploratory Data Analysis (EDA)",
    subHeading:
      "We plunge into step-by-step cooperation with organizations of any size, starting with EDA, to pass to the model development and training process. As a result of EDA, our clients retrieve valuable reports and data-based recommendations.",
  },
  {
    id: 3,
    title: "Implementing Machine Learning Algorithms",
    subHeading:
      "Whatever the challenges, our team's here to help implement machine learning algorithms in business workflow. We offer the best-fit approach for implementing and processing the provided data.",
  },
];

const whyWorkWithUsFeatures = [
  {
    id: 1,
    title: "Years of Experience",
    subHeading:
      "Since 2014, we've been developing ML-powered solutions helping businesses from various industries get digitized and grow.",
  },
  {
    id: 2,
    title: "Top-Notch Technology",
    subHeading:
      "Our team of data scientists and engineers use the latest technology for custom solution development.",
  },
  {
    id: 3,
    title: "Reliable Development Partner",
    subHeading:
      "A leading machine learning development company you can place your trust in.",
  },
];

const UseCaseAccordion = (props) => {
  const { useCase } = props;
  const { title, content } = useCase;
  return (
    <Accordion
      style={{
        borderRadius: "4px",
        overflow: "hidden",
        border: `1px solid ${colors.borderDefault}`,
        width: "100%",
      }}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        // sx={{ fontWeight: 800 }}
      >
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

const TechLanding = () => {
  const formRef = useRef(null);

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="techLandingPage">
      <div className="techTopCon">
        <div className="innerCon">
          <div className="title">
            Machine Learning Consulting and Development for
            <div style={{ color: "white" }}>
              <Typewriter
                options={{
                  strings: [
                    "Predictive Analytics",
                    "Text Analysis",
                    "Business Analytics",
                    "Insights from Visual Data",
                    "Sentiment Analysis",
                    "Image Recognition Solutions",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </div>
          </div>
          <div className="subTitle">
            Innovate and explore new opportunities in your field with our
            machine learning consulting and development services.
          </div>
        </div>
      </div>
      <div className="infoCon">
        <div className="info">
          The ENTER AI Tech team of seasoned data scientists and machine
          learning engineers experienced with unsupervised learning, supervised
          learning and reinforcement learning cooperates with clients to provide
          cutting-edge solutions for businesses of any size. We delve into each
          case and select best-fit innovative tools and proprietary
          technologies. The output is our custom machine learning models and
          solutions created in line with our clients’ business requirements and
          goals.
        </div>
      </div>
      <div className="featuresContainer" style={{ backgroundColor: "#e2f0fe" }}>
        <div className="heading">
          Start Your Next Machine Learning Project With ENTER AI Tech
        </div>
        <div className="featuresCon">
          {features.map((feature) => (
            <FeatureCard
              key={feature.id}
              feature={feature}
              containerStyle={{
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 30px 90px",
              }}
              titleStyle={{ fontSize: "1.3rem" }}
              subHeadingStyle={{ fontSize: "1rem", color: "gray" }}
            />
          ))}
        </div>
        <div className="subHeading">
          Have an idea of a breakthrough ML solution in mind?
        </div>
        <Button
          variant="outlined"
          style={{ textTransform: "none" }}
          onClick={scrollToForm}
        >
          Let's Discuss Your Project
        </Button>
      </div>
      <div className="featuresContainer">
        <div className="heading">Our Machine Learning Development Services</div>
        <div className="featuresCon">
          {services.map((feature) => (
            <FeatureCard
              key={feature.id}
              feature={feature}
              containerStyle={{ width: "350px" }}
              titleStyle={{ fontSize: "1.2rem" }}
              subHeadingStyle={{ fontSize: "1rem", color: "gray" }}
            />
          ))}
        </div>
        <Button
          variant="outlined"
          style={{ textTransform: "none" }}
          onClick={scrollToForm}
        >
          Contact Us
        </Button>
      </div>
      <div className="mlLifeCycleCon">
        <div className="mlLifeCycle">
          <div className="leftCon">
            <div className="heading">
              The Machine Learning Development Lifecycle at a Glance
            </div>
            <div>
              We take care of your entire project from day one, starting with
              clarifying the requirements and culminating with the delivery of a
              fully automated system.
            </div>
            <div>
              As one of the leading machine learning consulting and development
              companies, ENTER AI Tech presents a world-class data science team
              and machine learning consultants. Our experts have hands-on
              experience in applying cutting-edge ML tools and development
              approaches. They also use Cross-Industry Standard Process for Data
              Mining (CRISP-DM) to ensure the highest quality solutions and
              services that meet your requirements.
            </div>
          </div>
          <div className="rightCon">
            <img
              src="https://indatalabs.com/wp-content/uploads/2019/05/machine-learning-cube-data.svg"
              alt="ML Lifecycle"
            />
          </div>
        </div>
        <Button
          variant="outlined"
          style={{ textTransform: "none" }}
          onClick={scrollToForm}
        >
          Start Your Project
        </Button>
      </div>
      <div className="featuresContainer">
        <div className="heading">Our Machine Learning Consulting Services</div>
        <div className="featuresCon">
          {consultingServices.map((feature) => (
            <FeatureCard
              key={feature.id}
              feature={feature}
              containerStyle={{ width: "350px" }}
              titleStyle={{ fontSize: "1.2rem" }}
              subHeadingStyle={{ fontSize: "1rem", color: "gray" }}
            />
          ))}
        </div>
      </div>
      <div className="useCasesContainer">
        <div className="heading">Key Use Cases for Industries</div>
        <div className="useCases">
          <div className="useCasesInner">
            <UseCaseAccordion
              useCase={{
                title: "Healthcare & Pharma",
                content: (
                  <div>
                    Harness machine learning technology for better patient
                    outcomes. Forecast health risks and prevent diseases.
                    <li>disease prediction and prevention</li>
                    <li>efficient diagnosis and anomaly detection</li>
                    <li>healthcare record and workflow analysis</li>
                    <li>clinical trial opportunity identification</li>
                    <li>drug development</li>
                  </div>
                ),
              }}
            />
            <UseCaseAccordion
              useCase={{
                title: "Sport & Wellness",
                content: (
                  <div>
                    Optimize physical activity with top-notch technology.
                    Enhance patient physical therapy outcomes through error
                    detection algorithms.
                    <li>
                      human pose estimation for injury-free workouts with
                      personalized real-time feedback
                    </li>
                    <li>action recognition for fall detection</li>
                    <li>automated ball tracking</li>
                    <li>object tracking for players to train movements</li>
                    <li>sports performance analysis</li>
                  </div>
                ),
              }}
            />
          </div>
          <div className="useCasesInner">
            <UseCaseAccordion
              useCase={{
                title: "Marketing",
                content: (
                  <div>
                    Use ML to boost marketing strategies and increase sales.
                    <li>lead qualification</li>
                    <li>customer analytics</li>
                    <li>customer sentiment analysis</li>
                    <li>personalized offers</li>
                  </div>
                ),
              }}
            />
            <UseCaseAccordion
              useCase={{
                title: "Fintech",
                content: (
                  <div>
                    Advance financial decision making and strengthen security in
                    your organization with machine learning.
                    <li>fraud detection</li>
                    <li>credit scoring analytics</li>
                    <li>customer retention prediction</li>
                    <li>violent behavior detection</li>
                    <li>paperwork automation</li>
                    <li>customer service analytics</li>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <div className="whyWorkWithUs">
        <div className="leftCon">
          <img
            src="https://indatalabs.com/wp-content/uploads/2019/05/machine-learning-cube-people.svg"
            alt="work with us"
          />
        </div>
        <div className="rightCon">
          <div className="heading">Why Work with Us?</div>
          <div>
            As a machine learning consulting services provider, we dive into the
            clients' business problems and help them make machine-driven systems
            learn on their own to achieve outstanding performance. Our machine
            learning engineers focus on the development of profound
            self-learning solutions. Such systems are capable of performing well
            on unstructured data and giving a highly accurate output.
            Data-driven business success is what we offer to our clients.
          </div>
        </div>
      </div>
      <div className="whyWorkWithUsFeatures">
        {whyWorkWithUsFeatures.map((feature) => (
          <FeatureCard
            key={feature.id}
            feature={feature}
            containerStyle={{ width: "350px" }}
            titleStyle={{ fontSize: "1.2rem" }}
            subHeadingStyle={{ fontSize: "1rem", color: "gray" }}
          />
        ))}
      </div>
      <div className="contactUs" ref={formRef}>
        <div className="contactUsForm">
          <ContactUsForm
            title={"Contact ENTER AI"}
            subTitle={
              "Start your ML project with ENTER AI Tech and gain business outcomes unseen before. Leverage our deep learning consulting and development expertise."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TechLanding;
