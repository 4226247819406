import React from "react";
import "../styles/TextField.scss";

const TextField = (props) => {
  const {
    label,
    type,
    name,
    value,
    required = false,
    disabled = false,
    accept,
    placeholder,
    error,
    helperText,
    onChange,
    onBlur,
    onIconClick = null,
    style = {},
    innerStyle = {},
    inputStyle = {},
    icon,
  } = props;

  const isIconClickable = typeof onIconClick === "function";

  return (
    <div style={style}>
      <div
        className="textFieldContainer"
        style={{ ...innerStyle, opacity: disabled ? 0.4 : 1 }}
      >
        <div className="inputContainer">
          <div className={error ? "inputLabelError" : "inputLabel"}>
            {label}
            {required && "*"}
          </div>
          <input
            className="textInput"
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            accept={accept}
            style={inputStyle}
          />
        </div>
        {icon && (
          <div
            className="inputIcon"
            style={isIconClickable ? { cursor: "pointer" } : {}}
            onClick={isIconClickable ? onIconClick : () => {}}
          >
            {icon}
          </div>
        )}
      </div>
      {error && <div className="helperText">{helperText}</div>}
    </div>
  );
};

export default TextField;
