import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Logo from "../assets/techbrain.png";
import colors from "../styles/_colors.scss";
import "../styles/Navbar.scss";
import { setUserDetails } from "../redux/reducer";

const Navbar = (props) => {
  const { isTech = false, isResearch = false } = props;
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);

  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const { first_name, email, is_admin = false } = userDetails;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  useEffect(() => {
    if (screenWidth > 1080 && isNavExpanded) {
      setIsNavExpanded(false);
    }
  }, [screenWidth, isNavExpanded]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToProfile = () => {
    navigate("/edu/profile");
  };

  const logoutHandler = () => {
    dispatch(setUserDetails({}));
    localStorage.removeItem("userDetails");
    handleMenuClose();
    navigate("/edu/");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={navigateToProfile}>Profile</MenuItem>
      <MenuItem onClick={logoutHandler}>Logout</MenuItem>
    </Menu>
  );

  const eduNavLinks = [
    {
      name: "Home",
      to: "/edu/",
    },
    {
      name: "Courses",
      to: "/edu/courses",
    },
    {
      name: "Hire from us",
      to: "/edu/placements",
    },
    {
      name: "Work with us",
      to: "/edu/careers",
    },
    {
      name: "e-Library",
      to: "/edu/e-library",
    },
    {
      name: "Forum",
      to: "/edu/forum",
    },
    {
      name: "About",
      to: "/edu/about",
    },
  ];

  const adminNavLinks = [
    ...eduNavLinks,
    {
      name: "Create course",
      to: "/edu/create-course",
    },
  ];

  const techNavLinks = [
    {
      name: "Services",
      to: "/tech/",
    },
    {
      name: "About",
      to: "/tech/about",
    },
  ];

  const researchNavLinks = [
    {
      name: "Home",
      to: "/research/",
    },
  ];

  return (
    <div className="navbarContainer">
      {!isTech && (
        <div className="inquiryBanner">
          <LocalPhoneOutlinedIcon />
          <a
            style={{
              color: "inherit",
              textDecoration: "none",
              margin: "0 4px",
            }}
            href="tel:+917075585837"
          >
            +91-70755 85837
          </a>
          | For Inquiry: <WhatsAppIcon />
          <a
            style={{
              color: "inherit",
              textDecoration: "none",
              margin: "0 4px",
            }}
            href="tel:+919704491986"
          >
            +91-97044 91986
          </a>{" "}
          |{" "}
          <a
            style={{
              color: "inherit",
              textDecoration: "none",
              margin: "0 4px",
            }}
            href="tel:+918686968628"
          >
            +91-86869 68628
          </a>
        </div>
      )}
      <div className={isNavExpanded ? "navContainerExpanded" : "navContainer"}>
        <div className={isNavExpanded ? "navContentExpanded" : "navContent"}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/" className="navLogo">
              <div
                style={{
                  display: "flex",
                  columnGap: "8px",
                  alignItems: "center",
                }}
              >
                <img src={Logo} alt="Logo" height="40px" />
                ENTER AI
              </div>
            </Link>
            {isNavExpanded && (
              <span
                className="close-icon"
                onClick={() => setIsNavExpanded(false)}
              >
                <CloseIcon />
              </span>
            )}
          </div>
          {!isNavExpanded && (
            <span className="menu-icon" onClick={() => setIsNavExpanded(true)}>
              <MenuIcon />
            </span>
          )}
          <nav>
            <div className="navLinks">
              {(isTech
                ? techNavLinks
                : isResearch
                ? researchNavLinks
                : is_admin
                ? adminNavLinks
                : eduNavLinks
              ).map((navLink) => {
                return (
                  <div className="navItem" key={navLink.to}>
                    <NavLink
                      to={navLink.to}
                      className="navLink"
                      activeclassname="active"
                      onClick={() => {
                        if (isNavExpanded) {
                          setIsNavExpanded(false);
                        }
                      }}
                    >
                      <div>{navLink.name}</div>
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </nav>
          {isResearch ? null : !isTech ? (
            <div className="authBtns">
              {email ? (
                <div
                  style={{
                    marginRight: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>Hi, {first_name}</div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={handleProfileMenuOpen}
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              ) : (
                <div style={{ marginRight: "20px" }}>
                  <Link
                    to="/edu/login"
                    className="navLink"
                    onClick={() => {
                      if (isNavExpanded) {
                        setIsNavExpanded(false);
                      }
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="medium"
                      style={{
                        borderRadius: "5px",
                        textTransform: "none",
                        color: is_admin ? "black" : "white",
                        borderColor: is_admin
                          ? colors.borderDark
                          : colors.lightBlue,
                        backgroundColor: is_admin
                          ? "transparent"
                          : colors.lightBlue,
                      }}
                    >
                      <b>Log In</b>
                    </Button>
                  </Link>
                </div>
              )}

              {is_admin && (
                <div>
                  <Link
                    to="/edu/register"
                    className="navLink"
                    onClick={() => {
                      if (isNavExpanded) {
                        setIsNavExpanded(false);
                      }
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="medium"
                      style={{
                        borderRadius: "5px",
                        textTransform: "none",
                        color: "white",
                        backgroundColor: colors.lightBlue,
                      }}
                    >
                      <b>Register</b>
                    </Button>
                  </Link>
                </div>
              )}
              {renderMenu}
            </div>
          ) : (
            <div className="authBtns">
              <Button
                variant="outlined"
                size="medium"
                style={{
                  borderRadius: "5px",
                  textTransform: "none",
                  color: "white",
                  backgroundColor: colors.lightBlue,
                }}
              >
                <b>Contact Us</b>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
