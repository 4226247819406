import React from "react";

const DataScienceHighlights = () => {
  return (
    <React.Fragment>
      <div className="heading">Key Highlights of ENTER AI</div>
      <div className="highlightsContainer">
        <div className="container">
          <ul className="content">
            <li>Flexible Online Live and Classroom Training</li>
            <li>Completely Practical Oriented Approach</li>
            <li>Instructors from top institute alumni</li>
            <li>Certification on completion</li>
            <li>e-library with Learning Management System</li>
            <li>Designed hands-on projects for every concept</li>
            <li>Special sessions and Forum access for doubt clearance</li>
          </ul>
        </div>
        <div className="container">
          <ul className="content">
            <li>100% Internship guarantee program</li>
            <li>Guest lectures from industry and academic experts</li>
            <li>Expert-mentoring Tests & Assignments</li>
            <li>Industry relevant real time projects</li>
            <li>Career support and placement guidance</li>
            <li>Exclusive programs for Non-IT professionals</li>
            <li>3 Guaranteed Interviews by ENTER AI</li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataScienceHighlights;
