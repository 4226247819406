import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
// import Testimonies from "../components/Testimonies";
import colors from "../styles/_colors.scss";
import "../styles/Home.scss";
import Features from "../components/Features";
import DataScienceTraining from "../components/DataScienceTraining";
import DataScienceAdvantages from "../components/DataScienceAdvantages";
import Inquiry from "../components/Inquiry";
import HomeBG from "../assets/AllIsometricNov2-22.svg";
import { useSelector } from "react-redux";
import DataScienceBatchDetails from "../components/DataScienceBatchDetails";
import DataScienceCurriculum from "../components/DataScienceCurriculum";
import DataScienceHighlights from "../components/DataScienceHighlights";
import BrochurePDF from "../assets/Brochure.pdf";

const Home = () => {
  const [open, setOpen] = useState(false);
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const courses = useSelector((state) => state.appReducer.courses);
  const { email, is_admin } = userDetails;

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const navigateToLogin = () => {
    navigate("/edu/login");
  };

  const navigateToRegister = () => {
    navigate("/edu/register");
  };

  return (
    <div>
      <div className="homePage">
        <div className="homePageInnerCon">
          <div className="homeHeader">
            <div className="leftHeader">
              <span className="enter">ENTER</span>{" "}
              <span className="ai">AI</span> <br />{" "}
              <span>Connect with Intelligence</span>
            </div>
            <Inquiry open={open} setOpen={setOpen} courses={courses} />
            <div className="buttonsContainer">
              <Button
                variant="outlined"
                size="medium"
                style={{
                  width: "50%",
                  padding: "2vmin",
                  maxHeight: "50px",
                  borderRadius: "5px",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "white",
                  backgroundColor: colors.lightBlue,
                }}
                onClick={is_admin ? navigateToRegister : handleClickOpen}
              >
                <b>{is_admin ? "Register" : "Inquiry"}</b>
              </Button>
              {!email && (
                <Button
                  variant="outlined"
                  size="medium"
                  style={{
                    width: "50%",
                    padding: "2vmin",
                    maxHeight: "50px",
                    borderRadius: "5px",
                    textTransform: "none",
                    fontSize: "1rem",
                    color: "black",
                    borderColor: colors.borderDark,
                  }}
                  onClick={navigateToLogin}
                >
                  <b>Log In</b>
                </Button>
              )}
            </div>
          </div>
          <img src={HomeBG} alt="bg" className="introVideo" />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "-60px",
            marginLeft: "-80px",
          }}
        >
          <Button
            variant="outlined"
            size="medium"
            style={{
              maxHeight: "50px",
              borderRadius: "5px",
              textTransform: "none",
              fontSize: "1rem",
              backgroundColor: colors.lightBlue,
            }}
          >
            <b>
              <a
                download="ENTER_AI_Brochure.pdf"
                href={BrochurePDF}
                style={{ textDecoration: "none", color: "white" }}
              >
                Download Brochure
              </a>
            </b>
          </Button>
        </div>
      </div>
      <section className="featuresSection">
        <Features />
      </section>
      <section className="keyHighlightsSection">
        <DataScienceHighlights />
      </section>
      <section className="onlineTrainingBGSection">
        <div className="heading">Instructor-Led Live Online Training</div>
        <img
          className="backgroundImage"
          src="https://www.eleapsoftware.com/wp-content/uploads/2020/04/effective-virtual-trainings.jpg.webp"
          alt="virtual-trainings"
        />
      </section>
      <section className="aboutDataScienceSection">
        <DataScienceTraining />
      </section>
      <section className="batchDetailsSection">
        <DataScienceBatchDetails />
      </section>
      <section className="curriculumSection">
        <DataScienceCurriculum />
      </section>
      <section className="advantagesSection">
        <DataScienceAdvantages />
      </section>
      {/* <section className="testimoniesSection">
        <Testimonies />
      </section>
      <section className="companiesSection">
        <div className="heading">Key supporters</div>
        <Companies />
      </section> */}
    </div>
  );
};

export default Home;
