import React from "react";
import "../styles/Loader.scss";
import Backdrop from "@mui/material/Backdrop";

const Loader = () => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Backdrop>
  );
};

export default Loader;
