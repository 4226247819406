import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import { PDFReader } from "reactjs-pdf-reader";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import FolderIcon from "../assets/folder.png";
import ImageIcon from "../assets/picture.png";
import VideoIcon from "../assets/video.png";
import PDFIcon from "../assets/pdf.png";
import FileIcon from "../assets/files.png";
import Loader from "../components/Loader";
import { domain } from "../axios";
import "../styles/ELibraryItem.scss";

const renderIcon = ({ type }) => {
  switch (type) {
    case "FOLDER":
      return FolderIcon;
    case "IMAGE":
      return ImageIcon;
    case "VIDEO":
      return VideoIcon;
    case "PDF":
      return PDFIcon;
    default:
      return FileIcon;
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ELibraryItem = (props) => {
  const { item } = props;
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    id,
    name,
    type,
    item_link,
    file,
    // course,
    // parent_id,
    // children,
  } = item;

  let paramString = item_link?.split?.("?")?.[1];
  let queryString = new URLSearchParams(paramString);
  let videoId;
  for (let pair of queryString.entries()) {
    if (pair?.[0] === "id") {
      videoId = pair?.[1];
    }
  }

  const videoURL = `https://www.googleapis.com/drive/v3/files/${videoId}?key=AIzaSyAZuTwG4Gf5ByNBtF0auYb3E64DBnFLoUw&alt=media`;

  const fileToShow = file ? `${domain}${file}` : videoURL;

  const openImageViewer = useCallback(() => {
    setCurrentImage([fileToShow]);
    setIsImageViewerOpen(true);
  }, [fileToShow]);

  const closeImageViewer = () => {
    setCurrentImage([]);
    setIsImageViewerOpen(false);
  };

  const handleClose = () => {
    if (isVideoPlayerOpen) {
      setIsVideoPlayerOpen(false);
    } else if (isPDFViewerOpen) {
      setIsPDFViewerOpen(false);
    }
  };

  // const onFolderClick = () => {
  //   if (type === "FOLDER") {
  //     navigate(`/e-library/${id}`);
  //   } else return;
  // };

  const onImageClick = () => {
    if (type === "FOLDER") {
      navigate(`/edu/e-library/${id}`);
    } else if (type === "IMAGE") {
      openImageViewer();
    } else if (type === "VIDEO") {
      setIsVideoPlayerOpen(true);
    } else if (type === "PDF") {
      setIsPDFViewerOpen(true);
      setIsLoading(true);
    }
  };

  return (
    <>
      <div className="itemContainer" onClick={onImageClick}>
        <img
          src={renderIcon({ type })}
          alt="item"
          style={{ width: "100px", height: "90px", borderRadius: "10px" }}
        />
        <div className="name">{name}</div>
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          src={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
          backgroundStyle={{
            zIndex: 11,
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
        />
      )}
      <Dialog
        maxWidth={"md"}
        open={isVideoPlayerOpen || isPDFViewerOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {isLoading && <Loader />}
        {isVideoPlayerOpen && (
          <video controls autoPlay controlsList="nodownload">
            <source src={fileToShow} />
          </video>
        )}
        {isPDFViewerOpen && (
          <PDFReader
            url={fileToShow}
            showAllPage
            scale={1}
            onDocumentComplete={() => setIsLoading(false)}
          />
        )}
      </Dialog>
    </>
  );
};

export default ELibraryItem;
