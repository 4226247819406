import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CoursesBG from "../assets/courses_2.svg";
import colors from "../styles/_colors.scss";
import { advantages } from "../data/advantages";

const AdvantageAccordion = (props) => {
  const { advantage } = props;
  const { title, content } = advantage;
  return (
    <Accordion
      style={{
        borderRadius: "4px",
        overflow: "hidden",
        border: `1px solid ${colors.borderDefault}`,
        width: "100%",
      }}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        // sx={{ fontWeight: 800 }}
      >
        <Typography>
          <b>{title}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{content}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const DataScienceAdvantages = () => {
  return (
    <React.Fragment>
      <div className="contentContainer">
        <div className="leftContainer">
          <div className="heading">
            Advantages of Online Data Science course training
          </div>
          <div className="content">
            <div>
              {advantages.map((advantage) => {
                return (
                  <AdvantageAccordion
                    advantage={advantage}
                    key={advantage.id}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="illustration">
          <img src={CoursesBG} alt="course" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataScienceAdvantages;
