import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button, Tooltip, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import TextField from "../components/TextField";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import Loader from "../components/Loader";

import { newCourse } from "../axios";

import colors from "../styles/_colors.scss";
import "../styles/Login.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateNewCourse = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [chooseImage, setChooseImage] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const { is_admin } = userDetails;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (is_admin === false) {
      navigate("/edu/");
    }
  }, [navigate, is_admin]);

  const onSelectFile = (event) => {
    setImage(event.target.files[0]);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}

      <div className="main">
        <div>
          <Formik
            initialValues={{
              courseName: "",
              courseDuration: "",
              imageLink: "",
            }}
            validationSchema={Yup.object().shape({
              courseName: Yup.string()
                .max(255)
                .required("This field is required"),
              courseDuration: Yup.number()
                .max(255)
                .required("This field is required"),
              imageLink: !chooseImage
                ? Yup.string().required("This field is required")
                : Yup.string(),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setIsLoading(true);
              setSubmitting(true);
              const formData = new FormData();
              formData.append("name", values.courseName);
              formData.append("duration", values.courseDuration);
              if (image) {
                formData.append("thumbnail", image);
              } else {
                formData.append("thumbnail_url", values.imageLink);
              }
              await newCourse(formData)
                .then(() => {
                  setAlertMessage("Course created successfully");
                  setAlertSeverity("success");
                  setAlertOpen(true);
                })
                .catch(() => {
                  setAlertMessage("Course creation failed. Please try again.");
                  setAlertSeverity("error");
                  setAlertOpen(true);
                });
              setIsLoading(false);
              setSubmitting(false);
              resetForm();
              setImage("");
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              const submitBtnDisabled = Object.keys(errors).length > 0;
              return (
                <form onSubmit={handleSubmit}>
                  <div
                    style={{
                      minWidth: "280px",
                      width: "40vw",
                      maxWidth: "450px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      type="text"
                      name="courseName"
                      label="Course Name"
                      placeholder="Enter a course name"
                      required
                      icon={<ClassOutlinedIcon />}
                      helperText={touched.courseName && errors.courseName}
                      error={Boolean(touched.courseName && errors.courseName)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.courseName}
                      style={{ marginBottom: "20px", width: "100%" }}
                    />
                    <TextField
                      name="courseDuration"
                      label="Course Duration (in months)"
                      placeholder="Enter a duration (in months)"
                      type="number"
                      required
                      icon={<AccessTimeOutlinedIcon />}
                      helperText={
                        touched.courseDuration && errors.courseDuration
                      }
                      error={Boolean(
                        touched.courseDuration && errors.courseDuration
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.courseDuration}
                      style={{ marginBottom: "20px", width: "100%" }}
                    />
                    <div
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => setChooseImage(true)}
                    >
                      <TextField
                        name="image"
                        label="Course Image"
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        required={chooseImage}
                        disabled={!chooseImage}
                        icon={<AttachFileOutlinedIcon />}
                        onChange={onSelectFile}
                        onClick={() => setChooseImage(true)}
                        style={{ marginBottom: "20px", width: "100%" }}
                      />
                    </div>
                    <div style={{ marginBottom: "20px", fontWeight: 800 }}>
                      OR
                    </div>
                    <div
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => setChooseImage(false)}
                    >
                      <TextField
                        name="imageLink"
                        label="Image Link"
                        placeholder="Paste an image url"
                        required={!chooseImage}
                        disabled={chooseImage}
                        icon={<LinkOutlinedIcon />}
                        helperText={
                          !chooseImage && touched.imageLink && errors.imageLink
                        }
                        error={Boolean(
                          !chooseImage && touched.imageLink && errors.imageLink
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.imageLink}
                        style={{ marginBottom: "20px", minWidth: "100%" }}
                      />
                    </div>
                  </div>

                  <div className="submitBtns">
                    <Tooltip
                      title={
                        submitBtnDisabled ? "Please fill all the fields" : ""
                      }
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        style={{
                          borderRadius: "5px",
                          width: "150px",
                          height: "40px",
                          textTransform: "none",
                          fontWeight: "bold",
                          backgroundColor:
                            submitBtnDisabled || isSubmitting
                              ? "#9d9d9d"
                              : colors.lightBlue,
                          color: "white",
                          cursor:
                            submitBtnDisabled || isSubmitting
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        Create course
                      </Button>
                    </Tooltip>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateNewCourse;
