import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { Button, Tooltip, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../redux/reducer";
import TextField from "../components/TextField";
import Loader from "../components/Loader";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LoginRegisterSvg from "../assets/login2.svg";
import colors from "../styles/_colors.scss";
import "../styles/Login.scss";
import { login } from "../axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Login failed! Please check your credentials and try again
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}

      <div className="main">
        <div className="loginPage">
          <div className="loginHeader">
            <h1>Login to your account</h1>
          </div>
          <div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string()
                  .min(6, "Password must be at least 6 characters")
                  .max(255)
                  .required("Password is required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true);
                await login(values)
                  .then((res) => {
                    dispatch(setUserDetails(res.data));
                    localStorage.setItem(
                      "userDetails",
                      JSON.stringify(res.data)
                    );
                    navigate("/edu/");
                  })
                  .catch(() => {
                    setAlertOpen(true);
                  });
                setIsLoading(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                const submitBtnDisabled = Object.keys(errors).length > 0;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="loginForm">
                      <TextField
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        required
                        icon={<MailOutlineIcon />}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        style={{ marginBottom: "20px" }}
                      />
                      <TextField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter your password"
                        required
                        icon={
                          showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <VisibilityOutlinedIcon />
                          )
                        }
                        onIconClick={showPasswordHandler}
                        helperText={touched.password && errors.password}
                        error={Boolean(touched.password && errors.password)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        style={{ marginBottom: "20px" }}
                      />
                      <div className="submitBtns">
                        <Link to="/edu/" className="homeBtn">
                          <Button
                            variant="outlined"
                            size="medium"
                            style={{
                              borderRadius: "5px",
                              width: "150px",
                              height: "40px",
                              textTransform: "none",
                              color: "black",
                              borderColor: "#9d9d9d",
                              fontWeight: "bold",
                            }}
                          >
                            Go to Home
                          </Button>
                        </Link>
                        <Tooltip
                          title={
                            submitBtnDisabled
                              ? "Please fill all the fields"
                              : ""
                          }
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            style={{
                              borderRadius: "5px",
                              width: "150px",
                              height: "40px",
                              textTransform: "none",
                              fontWeight: "bold",
                              backgroundColor:
                                submitBtnDisabled || isSubmitting
                                  ? "#9d9d9d"
                                  : colors.lightBlue,
                              color: "white",
                              cursor:
                                submitBtnDisabled || isSubmitting
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Login
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <img className="loginImg" src={LoginRegisterSvg} alt="Login" />
      </div>
    </>
  );
};

export default Login;
