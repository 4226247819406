import React from "react";

const Forum = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        fontSize: "5vmin",
        fontWeight: "bold",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          marginBottom: "200px",
          backgroundColor: "#e2f0fc",
          width: "fit-content",
          padding: "5px 10px",
          borderRadius: "8px",
        }}
      >
        “Data are becoming the new raw material of business.”
      </span>
      <div>Coming Soon...</div>
    </div>
  );
};

export default Forum;
