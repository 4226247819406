import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import colors from "../styles/_colors.scss";
import ELibraryBG2 from "../assets/e-lib.svg";
import { useSelector } from "react-redux";
import ELibraryItem from "../components/ELibraryItem";
import Loader from "../components/Loader";
import "../styles/ELibrary.scss";
import { getELibraryItems } from "../axios";
import AddELibraryItem from "../components/AddELibraryItem";

const ELibrary = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const { email: user_email = null, is_admin = false } = userDetails;
  const [eLibraryItems, setELibraryItems] = useState([]);
  const [courseId, setCourseId] = useState("");
  const { email } = userDetails;
  const params = useParams();
  const { id: parentId = null } = params || {};

  const goToLoginPage = () => {
    navigate("/edu/login");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchELibraryItems = async () => {
      setIsLoading(true);
      await getELibraryItems(parentId, user_email)
        .then((res) => {
          setCourseId(res.data?.course_id);
          setELibraryItems(res.data?.data);
        })
        .catch((err) => console.log(err));
      setIsLoading(false);
    };
    if (user_email) {
      fetchELibraryItems();
    }
  }, [parentId, user_email]);

  return (
    <>
      {isLoading && <Loader />}
      {is_admin && parentId && (
        <>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add new item</DialogTitle>
            <DialogContent>
              <AddELibraryItem courseId={courseId} parentId={parentId} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px",
              marginBottom: "0px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClickOpen}
              style={{
                textTransform: "none",
              }}
            >
              + Add new
            </Button>
          </div>
        </>
      )}
      <div className="eLibraryPage" onContextMenu={(e) => e.preventDefault()}>
        {email ? (
          <div className="folders">
            {eLibraryItems.length > 0 ? (
              eLibraryItems.map((item) => (
                <ELibraryItem key={item.id} item={item} />
              ))
            ) : !isLoading ? (
              <div style={{ marginTop: "30px", fontSize: "2rem" }}>
                No items here
              </div>
            ) : null}
          </div>
        ) : (
          <>
            <img className="eLibraryPageImg" src={ELibraryBG2} alt="e-lib" />
            <div
              style={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "40vh",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "3.5vmin",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Welcome to the ENTER AI Digital Space
              </div>
              <div>
                <div>Discover and Learn More...</div>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={goToLoginPage}
                  style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: colors.lightBlue,
                    padding: "10px",
                    // width: "30%",
                    fontSize: "2vmin",
                  }}
                >
                  <b>Login to proceed</b>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ELibrary;
