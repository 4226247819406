import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tooltip,
  Snackbar,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  InputLabel,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import TextField from "../components/TextField";
import ClassOutlinedIcon from "@mui/icons-material/ClassOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import Loader from "../components/Loader";
import { newELibraryItem } from "../axios";
import colors from "../styles/_colors.scss";
import "../styles/Login.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const eLibraryItemType = [
  {
    id: 1,
    label: "FOLDER",
  },
  { id: 2, label: "VIDEO" },
  { id: 3, label: "IMAGE" },
  { id: 4, label: "PDF" },
  { id: 5, label: "OTHER" },
];

const AddELibraryItem = ({ courseId, parentId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState("");
  const [chooseFile, setChooseFile] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const { is_admin } = userDetails;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (is_admin === false) {
      navigate("/edu/");
    }
  }, [navigate, is_admin]);

  const onSelectFile = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}

      <div>
        <Formik
          initialValues={{
            name: "",
            type: "",
            link: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required("This field is required"),
            type: Yup.string().max(255).required("This field is required"),
            link: !chooseFile
              ? Yup.string().when("type", {
                  is: (type) => type !== "FOLDER",
                  then: Yup.string().required("This field is required"),
                })
              : Yup.string(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setIsLoading(true);
            setSubmitting(true);
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("type", values.type);
            formData.append("course", courseId);
            formData.append("parent_id", parentId);
            if (file) {
              formData.append("file", file);
            } else {
              let updatedLink = values.link;
              if (updatedLink.includes("drive.google.com")) {
                const driveItemLink = updatedLink?.match(
                  /[-\w]{25,}(?!.*[-\w]{25,})/
                )?.[0];
                updatedLink = `https://drive.google.com/u/1/uc?id=${driveItemLink}&export=download&confirm=t`;
              }
              formData.append("item_link", updatedLink);
            }
            await newELibraryItem(formData)
              .then(() => {
                setAlertMessage("Created successfully");
                setAlertSeverity("success");
                setAlertOpen(true);
              })
              .catch(() => {
                setAlertMessage("Something went wrong");
                setAlertSeverity("error");
                setAlertOpen(true);
              });
            setIsLoading(false);
            setSubmitting(false);
            resetForm();
            setFile("");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const submitBtnDisabled = Object.keys(errors).length > 0;
            return (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    minWidth: "280px",
                    width: "40vw",
                    maxWidth: "450px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    type="text"
                    name="name"
                    label="Name"
                    placeholder="Enter a name"
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    required
                    icon={<ClassOutlinedIcon />}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    style={{ marginBottom: "20px", width: "100%" }}
                  />
                  <FormControl
                    style={{ marginBottom: "20px", width: "100%" }}
                    size="small"
                    required
                    error={Boolean(touched.type) && Boolean(errors.type)}
                  >
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Select type
                    </InputLabel>
                    <Select
                      name="type"
                      labelId="demo-simple-select-autowidth-label"
                      id="type"
                      value={values.type}
                      label="Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                    >
                      {eLibraryItemType.map((item) => (
                        <MenuItem value={item.label} key={item.id}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {Boolean(touched.type) &&
                        Boolean(errors.type) &&
                        errors.type}
                    </FormHelperText>
                  </FormControl>
                  {values.type !== "FOLDER" && (
                    <>
                      <div
                        style={{ width: "100%", cursor: "pointer" }}
                        onClick={() => setChooseFile(false)}
                      >
                        <TextField
                          name="link"
                          label="File Link"
                          placeholder="Paste file link"
                          innerStyle={{ height: "40px" }}
                          inputStyle={{ lineHeight: "30px" }}
                          required={!chooseFile}
                          disabled={chooseFile}
                          icon={<LinkOutlinedIcon />}
                          helperText={
                            !chooseFile && touched.link && errors.link
                          }
                          error={Boolean(
                            !chooseFile && touched.link && errors.link
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.link}
                          style={{ marginBottom: "20px", minWidth: "100%" }}
                        />
                      </div>
                      <div style={{ marginBottom: "20px", fontWeight: 800 }}>
                        OR
                      </div>
                      <div
                        style={{ width: "100%", cursor: "pointer" }}
                        onClick={() => setChooseFile(true)}
                      >
                        <TextField
                          name="file"
                          label="File"
                          type="file"
                          innerStyle={{ height: "40px" }}
                          inputStyle={{ lineHeight: "30px" }}
                          required={chooseFile}
                          disabled={!chooseFile}
                          icon={<AttachFileOutlinedIcon />}
                          onChange={onSelectFile}
                          onClick={() => setChooseFile(true)}
                          style={{ marginBottom: "20px", width: "100%" }}
                        />
                      </div>{" "}
                    </>
                  )}

                  <div className="submitBtns">
                    <Tooltip
                      title={
                        submitBtnDisabled ? "Please fill all the fields" : ""
                      }
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        size="medium"
                        style={{
                          borderRadius: "5px",
                          width: "150px",
                          height: "40px",
                          textTransform: "none",
                          fontWeight: "bold",
                          backgroundColor:
                            submitBtnDisabled || isSubmitting
                              ? "#9d9d9d"
                              : colors.lightBlue,
                          color: "white",
                          cursor:
                            submitBtnDisabled || isSubmitting
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        Create
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      {/* </div> */}
    </>
  );
};

export default AddELibraryItem;
