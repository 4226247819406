import React from "react";
import DataScienceBG from "../assets/AllIsometricOct2-04.svg";

const DataScienceTraining = () => {
  return (
    <React.Fragment>
      <div className="heading">Data Science Certification Training Course</div>
      <div className="contentContainer">
        <div className="content">
          <ul
            style={{
              textAlign: "start",
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <li>
              Data Science Course will help you start your career as a Data
              Scientist and become an certified data scientist.
            </li>
            <li>
              Learn all about data analytics, tools, and operations for data
              science certification and meet the growing demand for these skills
              in the industry.
            </li>
            <li>
              The data science training will accelerate your career as it covers
              relevant topics to work in real-time scenarios for professionals.
            </li>
            <li>
              The online data science training program will help you learn in a
              structured way to gain in-depth knowledge and earn your Data
              Science Certification for a peak in your career.
            </li>
            <li>
              Enroll now in the best data science course with <b>ENTER AI</b>{" "}
              and get placed well in great companies.
            </li>
          </ul>
        </div>
        <div className="illustration">
          <img src={DataScienceBG} alt="bg" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataScienceTraining;
