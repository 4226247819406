import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CourseCard from "../components/CourseCard";
import CoursesBG from "../assets/courses_2.svg";
import "../styles/Courses.scss";

const Courses = () => {
  const courses = useSelector((state) => state.appReducer.courses);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="coursePage">
      <div className="header">
        Available Courses
        <img src={CoursesBG} alt="courses" />
      </div>
      <div className="courses">
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </div>
    </div>
  );
};

export default Courses;
