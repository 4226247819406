export const modules = [
  {
    id: 1,
    name: "Core Python Programming",
    syllabus: [
      "Introduction to python",
      "Data types and data structures",
      "Control flow and conditional statements",
      "Functions",
      "File handling",
    ],
  },
  {
    id: 2,
    name: "Data Analysis using Python",
    syllabus: [
      "Numpy- numerical python",
      "Data manipulation with Pandas",
      "Data visualization using Matplotlib and Pandas",
      "Case study on Exploratory Data Analysis and visualizations",
      "Data Mining and preparation",
    ],
  },
  {
    id: 3,
    name: "Advanced Statistics",
    syllabus: [
      "Data gathering techniques",
      "Descriptive stats",
      "Linear algebra, calculus",
      "Probability distribution",
      "Inferential statistics",
    ],
  },
  {
    id: 4,
    name: "Machine Learning",
    syllabus: [
      "> Supervised Learning:",
      "Module 5: Regression Techniques",
      "Module 6: Classification Techniques",
    ],
  },
  {
    id: 5,
    name: "Regression Techniques:",
    syllabus: [
      "Linear regression",
      "Multiple linear regression",
      "Evaluation of metrics of regression techniques",
      "Polynomial regression",
      "Time series (forecasting)",
      "Regularization techniques",
    ],
  },
  {
    id: 6,
    name: "Classification Techniques",
    syllabus: ["Logistic Regression", "Naive Bayes"],
  },
  {
    id: 7,
    name: "Tree-based modules:",
    syllabus: [
      "Decision tree",
      "Ensemble methods",
      "Random Forest",
      "Bagging",
      "Boosting",
    ],
  },
  {
    id: 8,
    name: "Distance-based modules:",
    syllabus: ["K-Nearest Neighbor", "Support Vector Machine"],
  },
  {
    id: 9,
    name: "Unsupervised Learning:",
    syllabus: [
      "Principal Component Analysis",
      "Recommender systems",
      "K-mean clustering",
      "Hierarchical clustering",
    ],
  },
  {
    id: 10,
    name: "Deep Learning",
    syllabus: [
      "Introduction to neural networks",
      "Tensorflow",
      "Activations, Optimizers & Loss Functions",
      "Artificial Neural Networks",
      "Convolutional Neural Networks",
      "Recurrent Neural Networks",
      "Long Short Term Memory",
    ],
  },
  {
    id: 11,
    name: "Real-Time Projects",
  },
];
