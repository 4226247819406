import OnlineCoursesBG from "../assets/courses.svg";
import InternshipBG from "../assets/CorporateIntern.svg";
import ResearchBG from "../assets/research_bg.svg";

export const features = [
  {
    id: 1,
    title: "Online Courses",
    subHeading: "We propose you a proposal and discuss nitty-gritty like",
    image: OnlineCoursesBG,
    clickable: true,
  },
  {
    id: 2,
    title: "Internships",
    subHeading: "We propose you a proposal and discuss nitty-gritty like",
    image: InternshipBG,
    clickable: true,
  },
  {
    id: 3,
    title: "Research Assistance",
    subHeading: "We propose you a proposal and discuss nitty-gritty like",
    image: ResearchBG,
    clickable: true,
  },
];
