import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import "../styles/FeatureCard.scss";

const FeatureCard = ({
  feature,
  containerStyle = {},
  titleStyle = {},
  subHeadingStyle = {},
}) => {
  const { id, title, image = null, subHeading, clickable = false } = feature;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const onClickHandler = () => {
    if (clickable) {
      if (id === 1) {
        navigate("/edu/courses");
      } else {
        handleClickOpen();
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please reach out to us on{" "}
            <b>
              <a href="mailto:info@enterai.in">info@enterai.in</a>
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Okay!</Button>
        </DialogActions>
      </Dialog>
      <div
        className="featureCard"
        onClick={onClickHandler}
        style={{ ...containerStyle, cursor: clickable ? "pointer" : "auto" }}
      >
        {image && <img src={image} alt="feature" height="200px" />}
        <div className="title" style={titleStyle}>
          {title}
        </div>
        {!image && (
          <div className="subHeading" style={subHeadingStyle}>
            {subHeading}
          </div>
        )}
      </div>
    </>
  );
};

export default FeatureCard;
