export const advantages = [
  {
    id: 1,
    title: "Greater Decision Making Power",
    content:
      "As a Data Science professional, your skillset will make you a highly covetable part of the team. What this means is that you will be one of the core decision-making arms of a company. This, in turn, will help you gain organizational recognition and credibility, further boosting your CV and prospects.",
  },
  {
    id: 2,
    title: "Low Entry Competition",
    content:
      "Compared to other IT jobs, Data Science is a relatively new field. Therefore, there is a huge demand and supply gap when it comes to jobs. With the course and even a little experience, you can leverage your skills to gain a first-mover advantage and quickly grow in the field and gain seniority.",
  },
  {
    id: 3,
    title: "Future-Proof Career",
    content:
      "Data Science is a future-proof career that will be the driving force behind industries in the 21st Century. With a strong background and experience in the data science field, you are almost certainly guaranteeing your career for future decades to come. With the combination of knowledge of technologies such as machine learning and artificial intelligence, you will become an invaluable resource to hiring companies.",
  },
  {
    id: 4,
    title: "Diversified Work Across Sectors",
    content:
      "Data scientists can get work in a variety of industries and sectors. From healthcare and pharmaceuticals to financial services, manufacturing, logistics, and telecommunications, as a Data Science certified professional, you will have your pick of jobs to choose from.",
  },
  {
    id: 5,
    title: "Outstanding Employment Opportunities",
    content:
      "Top tech and Fortune 500 companies like Google, Amazon, Microsoft, Intel, Vodafone, etc., are on the lookout for data scientists and are willing to pay top dollars for their services. As a data science professional, you can get to work with the best companies nationally and globally. There is an extreme shortage of qualified data scientists worldwide, which will make you stand out easier.",
  },
];
