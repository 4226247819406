import React, { useRef, useState } from "react";
import colors from "../styles/_colors.scss";
import PlacementsIllustration from "../assets/JobInterview.svg";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../components/Loader";
import ContactUsForm from "../components/ContactUsForm";
import "../styles/Placements.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Placements = () => {
  const formRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const scrollToForm = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}
      <div className="placementsPage">
        <div className="topContainer">
          <div className="leftContainer">
            <div className="heading">
              <span>Find and Hire</span> <br />
              The Right Data Talent
            </div>
            <div className="subHeading">
              Get access to one of the finest sources of data talent, you can
              effectively find with the precise set of skills that you need to
              hire for.
            </div>
            <div className="buttonsContainer">
              <Button
                variant="outlined"
                size="medium"
                style={{
                  marginTop: "10px",
                  borderRadius: "5px",
                  textTransform: "none",
                  color: "white",
                  backgroundColor: colors.lightBlue,
                  padding: "10px",
                  width: "50%",
                  fontSize: "2.5vmin",
                }}
                onClick={scrollToForm}
              >
                <b>Hire Now</b>
              </Button>
            </div>
          </div>
          <div className="rightContainer">
            <img src={PlacementsIllustration} alt="placements" />
          </div>
        </div>
        <div className="placements heading">
          Build your Data & Engineering Team with industry ready top skilled
          tech talent.
        </div>
        <div className="formContainer" ref={formRef}>
          <div className="content">
            <div className="heading">
              Recruiting data scientists and data talent is extremely
              competitive and highly complex
            </div>
            <div className="subTitle">
              If you’ve tried to recruit for data talent, you know the process
              comes with its own challenges.
            </div>
            <div className="subTitle">
              From a high demand in roles with a low supply of talent, to
              challenges in testing technical skills, we know how difficult it
              can be to recruit top talent for your open data roles.
            </div>
          </div>
          <ContactUsForm
            setIsLoading={setIsLoading}
            setAlertMessage={setAlertMessage}
            setAlertSeverity={setAlertSeverity}
            setAlertOpen={setAlertOpen}
            title={"Interested in hiring from us?"}
            subTitle={"Share your details and we will get in touch."}
          />
        </div>
        {/* <div className="jobOpportunitesContainer">
        <div className="jobs">
          <div className="heading">New Job Opportunities</div>
          <div className="jobOpportunites">
            {jobOpportunites.map((jobDetails) => (
              <JobOpportunityCard key={jobDetails.id} jobDetails={jobDetails} />
            ))}
          </div>
        </div>
        <div className="internships">
          <div className="heading">New Internship Opportunities</div>
          <div className="jobOpportunites">
            {jobOpportunites.map((jobDetails) => (
              <JobOpportunityCard key={jobDetails.id} jobDetails={jobDetails} />
            ))}
          </div>
        </div>
      </div>
      <div className="companiesContainer">
        <div className="heading">Companies</div>
        <Companies />
      </div> */}
      </div>
    </>
  );
};

export default Placements;
