import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextField from "./TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../components/Loader";
import { changePassword } from "../axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ChangePassword = ({ setShowChangePassword = null }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const { user_id = null } = userDetails;

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}
      <div>
        <Formik
          initialValues={{
            password1: "",
            password2: "",
          }}
          validationSchema={Yup.object().shape({
            password1: Yup.string()
              .min(6, "New password must be at least 6 characters")
              .required("This field is required"),
            password2: Yup.string()
              .min(6, "New password must be at least 6 characters")
              .required("This field is required")

              .oneOf([Yup.ref("password1"), null], "Passwords must match"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            await changePassword(values, user_id)
              .then((res) => {
                if (res.data.success) {
                  setAlertSeverity("success");
                  setAlertMessage("Password changed successfully");
                  setAlertOpen(true);
                  if (setShowChangePassword) {
                    setShowChangePassword(false);
                  }
                }
              })
              .catch(() => {
                setAlertSeverity("error");
                setAlertMessage("Please check your password");
                setAlertOpen(true);
              });
            setIsLoading(false);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            // const submitBtnDisabled = Object.keys(errors).length > 0;
            return (
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                    width: "100%",
                    padding: "1vw",
                  }}
                >
                  <TextField
                    label="New Password"
                    size="small"
                    name="password1"
                    type="password"
                    fullWidth
                    required
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.password1 && errors.password1}
                    error={Boolean(touched.password1 && errors.password1)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password1}
                  />
                  <TextField
                    label="Confirm password"
                    size="small"
                    fullWidth
                    name="password2"
                    type="password"
                    required
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.password2 && errors.password2}
                    error={Boolean(touched.password2 && errors.password2)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password2}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    autoFocus
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      fontWeight: "bold",
                      marginTop: "40px",
                    }}
                  >
                    Change Password
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;
