import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import LandingIllustration from "../assets/landing_middle.svg";
// import EduIllustration from "../assets/edu.svg";
// import ResearchIllustration from "../assets/research.svg";
// import TechIllustration from "../assets/tech.svg";
import "../styles/Landing.scss";

const Landing = () => {
  const navigate = useNavigate();

  // const goToTech = () => {
  //   navigate("/tech/");
  // };

  // const goToEdu = () => {
  //   navigate("/edu/");
  // };

  // const goToResearch = () => {
  //   navigate("/research/");
  // };

  useEffect(() => {
    navigate("/edu/");
  }, []);

  return (
    <>
      <div
        className="landingPage"
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          rowGap: "50vmin",
          justifyContent: "center",
        }}
      >
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="techIllustration">
            <img src={TechIllustration} alt="tech" onClick={goToTech} />
            <div className="label" onClick={goToTech}>
              ENTER AI - TECH
            </div>
          </div>
        </div>
        <div className="logoContainer"></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            columnGap: "35vw",
          }}
        >
          <div className="researchIllustration">
            <img
              src={ResearchIllustration}
              alt="research"
              onClick={goToResearch}
            />
            <div className="label" onClick={goToResearch}>
              ENTER AI - RESEARCH
            </div>
          </div>

          <div className="eduIllustration">
            <img src={EduIllustration} alt="edu" onClick={goToEdu} />
            <div className="label" onClick={goToEdu}>
              ENTER AI - EDU
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Landing;
