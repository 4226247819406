import React from "react";

const DataScienceBatchDetails = () => {
  return (
    <React.Fragment>
      <div className="heading">Certified Data Science Demo/Batch details</div>
      <div className="batchDetails">
        <div className="gridHead">Session Type</div>
        <div className="gridHead">Date</div>
        <div>New Batch – Weekdays (Online)</div>
        <div>May 2nd week, 2023</div>
        <div>New Batch – Weekends (Online)</div>
        <div>Coming Soon</div>
        <div>Demo (Offline)</div>
        <div>N/A</div>
      </div>
    </React.Fragment>
  );
};

export default DataScienceBatchDetails;
