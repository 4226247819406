import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  courses: [],
};

export const appReducer = createSlice({
  name: "addStore",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setCourses: (state, action) => {
      state.courses = action.payload;
    },
  },
});

export const { setUserDetails, setCourses } = appReducer.actions;

export default appReducer.reducer;
