import axios from "axios";

export const domain = "https://backend.enterai.in";
// export const domain = "http://localhost:8000";

export const login = (requestBody) => {
  return axios.post(`${domain}/api/auth/login/`, requestBody);
};

export const register = (requestBody) => {
  return axios.post(`${domain}/api/auth/register/`, requestBody);
};

export const allCourses = () => {
  return axios.get(`${domain}/api/courses/`);
};

export const newInquiry = (requestBody) => {
  return axios.post(`${domain}/api/inquiries/new/`, requestBody);
};

export const newCourse = (requestBody) => {
  return axios.post(`${domain}/api/courses/new/`, requestBody);
};

export const newHireInquiry = (requestBody) => {
  return axios.post(`${domain}/api/inquiries/new-hire-inquiry/`, requestBody);
};

export const newWorkInquiry = (requestBody) => {
  return axios.post(`${domain}/api/inquiries/new-work-inquiry/`, requestBody);
};

export const getELibraryItems = (parentId = null, user_email = null) => {
  let queryParams = "";
  if (user_email) {
    queryParams += `?user_email=${user_email}`;
    if (parentId) {
      queryParams += `&parent_id=${parentId}`;
    }
  }
  return axios.get(`${domain}/api/e_library/${queryParams}`);
};

export const newELibraryItem = (formData) => {
  return axios.post(`${domain}/api/e_library/`, formData);
};

export const changePassword = (requestBody, user_id = null) => {
  return axios.post(
    `${domain}/api/auth/reset-password/${user_id}/`,
    requestBody
  );
};
