import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
import TextField from "./TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import { FormHelperText } from "@mui/material";
import { newInquiry } from "../axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../components/Loader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Inquiry = (props) => {
  const { open, setOpen, courses } = props;
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}
      <BootstrapDialog
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen={fullScreen}
        style={{ zIndex: 10 }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpen(false);
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            I'm Interested
          </div>
        </BootstrapDialogTitle>
        <DialogContent>
          <div>
            <Formik
              initialValues={{
                email: "",
                name: "",
                course: "",
                phoneNumber: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                name: Yup.string().max(255).required("Name is required"),
                phoneNumber: Yup.string().required("Phone number is required"),
                course: Yup.string().required("Course is required"),
              })}
              onSubmit={async (values, { setSubmitting }) => {
                setIsLoading(true);
                await newInquiry({
                  name: values.name,
                  phone_number: values.phoneNumber,
                  email: values.email,
                  course: values.course,
                })
                  .then((res) => {
                    if (res.data?.inquiry_id) {
                      setAlertSeverity("success");
                      setAlertMessage("Enquiry sent successfully");
                      setAlertOpen(true);
                      setOpen(false);
                    }
                  })
                  .catch(() => {
                    setAlertSeverity("success");
                    setAlertMessage("We have already received your inquiry");
                    setAlertOpen(true);
                    setOpen(false);
                  });
                setIsLoading(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => {
                // const submitBtnDisabled = Object.keys(errors).length > 0;
                return (
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                        width: fullScreen ? "90%" : "400px",
                        padding: "1vw",
                      }}
                    >
                      <TextField
                        label="Name"
                        size="small"
                        name="name"
                        required
                        fullWidth
                        type="text"
                        innerStyle={{ height: "40px" }}
                        inputStyle={{ lineHeight: "30px" }}
                        helperText={touched.name && errors.name}
                        error={Boolean(touched.name && errors.name)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <TextField
                        label="Phone Number"
                        type="tel"
                        size="small"
                        name="phoneNumber"
                        fullWidth
                        required
                        innerStyle={{ height: "40px" }}
                        inputStyle={{ lineHeight: "30px" }}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                      />
                      <TextField
                        label="Email"
                        type="email"
                        size="small"
                        fullWidth
                        name="email"
                        required
                        innerStyle={{ height: "40px" }}
                        inputStyle={{ lineHeight: "30px" }}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <FormControl
                        style={{ width: "100%" }}
                        size="small"
                        required
                        error={
                          Boolean(touched.course) && Boolean(errors.course)
                        }
                      >
                        <InputLabel id="demo-simple-select-autowidth-label">
                          Interested in
                        </InputLabel>
                        <Select
                          name="course"
                          labelId="demo-simple-select-autowidth-label"
                          id="course"
                          value={values.course}
                          label="Select a course"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                        >
                          {courses.map((item) => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {Boolean(touched.course) &&
                            Boolean(errors.course) &&
                            errors.course}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        autoFocus
                        variant="outlined"
                        style={{
                          textTransform: "none",
                          fontWeight: "bold",
                          marginTop: fullScreen ? "40px" : "",
                        }}
                      >
                        Inquiry now
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default Inquiry;
