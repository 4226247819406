import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Tooltip, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import TextField from "../components/TextField";
import Select from "react-select";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import LoginRegisterSvg from "../assets/online_learning.svg";
import Loader from "../components/Loader";
import { register } from "../axios";
import colors from "../styles/_colors.scss";
import "../styles/Login.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState(false);
  const userDetails = useSelector((state) => state.appReducer.userDetails);
  const courses = useSelector((state) => state.appReducer.courses) ?? [];
  const { is_admin } = userDetails;

  const navigate = useNavigate();

  useEffect(() => {
    if (!is_admin) {
      navigate("/edu/");
    }
  }, [navigate, is_admin]);

  const coursesOptions = courses.map((course) => ({
    label: course.name,
    value: course.id,
  }));

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}
      <div className="main">
        <div className="loginPage">
          <div className="loginHeader">
            <h1>Create new account</h1>
            <div>
              Already a member?{" "}
              <NavLink
                to="/edu/login"
                style={{ textDecoration: "none", color: colors.lightBlue }}
              >
                <b>Log In</b>
              </NavLink>
            </div>
          </div>
          <div>
            <Formik
              initialValues={{
                email: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                courses: [],
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                firstName: Yup.string()
                  .max(255)
                  .required("First name is required"),
                lastName: Yup.string().max(255),
                phoneNumber: Yup.string().required("Phone number is required"),
              })}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, setFieldValue }
              ) => {
                setIsLoading(true);

                await register({
                  email: values.email,
                  first_name: values.firstName,
                  last_name: values.lastName,
                  password: values.password,
                  phone_number: values.phoneNumber,
                  courses: values.courses.map((course) => course.value),
                })
                  .then(() => {
                    setAlertSeverity("success");
                    setAlertMessage("Student register successful");
                    setAlertOpen(true);
                    resetForm();
                    setFieldValue?.("courses", []);
                  })
                  .catch(() => {
                    setAlertSeverity("error");
                    setAlertMessage("Something went wrong. Please try again.");
                    setAlertOpen(true);
                  });
                setIsLoading(false);
                setSubmitting(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                const submitBtnDisabled =
                  Object.keys(errors).length > 0 && values.courses.length === 0;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="loginForm">
                      <div className="nameContainer">
                        <div className="firstNameContainer">
                          <TextField
                            label="First Name"
                            type="text"
                            name="firstName"
                            placeholder="Enter your first name"
                            required
                            icon={<PersonOutlineIcon />}
                            helperText={touched.firstName && errors.firstName}
                            error={Boolean(
                              touched.firstName && errors.firstName
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                        </div>
                        <div>
                          <TextField
                            label="Last Name"
                            type="text"
                            name="lastName"
                            placeholder="Enter your last name"
                            icon={<PersonOutlineIcon />}
                            helperText={touched.lastName && errors.lastName}
                            error={Boolean(touched.lastName && errors.lastName)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                        </div>
                      </div>
                      <div style={{ marginBottom: "20px" }}>
                        <Select
                          defaultValue={[]}
                          isMulti
                          name="courses"
                          options={coursesOptions}
                          onChange={(value) => setFieldValue("courses", value)}
                          onBlur={handleBlur}
                          classNamePrefix="select"
                          placeholder="Select courses"
                        />
                      </div>
                      <TextField
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        required
                        icon={<MailOutlineIcon />}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        style={{ marginBottom: "20px" }}
                      />
                      <TextField
                        label="Phone Number"
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        required
                        icon={<PhoneOutlinedIcon />}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        error={Boolean(
                          touched.phoneNumber && errors.phoneNumber
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phoneNumber}
                        style={{ marginBottom: "20px" }}
                      />
                      <div className="submitBtns">
                        <Link to="/edu/" className="homeBtn">
                          <Button
                            variant="outlined"
                            size="medium"
                            style={{
                              borderRadius: "5px",
                              width: "150px",
                              height: "40px",
                              textTransform: "none",
                              color: colors.black,
                              borderColor: colors.borderDark,
                              fontWeight: "bold",
                            }}
                          >
                            Go to Home
                          </Button>
                        </Link>
                        <Tooltip
                          title={
                            submitBtnDisabled
                              ? "Please fill all the fields"
                              : ""
                          }
                        >
                          <Button
                            type="submit"
                            variant="contained"
                            size="medium"
                            style={{
                              borderRadius: "5px",
                              width: "150px",
                              height: "40px",
                              textTransform: "none",
                              fontWeight: "bold",
                              backgroundColor:
                                submitBtnDisabled || isSubmitting
                                  ? colors.borderDark
                                  : colors.lightBlue,
                              color: "white",
                              cursor:
                                submitBtnDisabled || isSubmitting
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            Register
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <img className="loginImg" src={LoginRegisterSvg} alt="Register" />
      </div>
    </>
  );
};

export default Register;
