import React from "react";
import ResearchHomeGIF from "../../assets/research_home.gif";

const ResearchHome = () => {
  return (
    <div>
      <img src={ResearchHomeGIF} alt="research" width={"100%"} />
      <div style={{ fontSize: "1.5rem", margin: "20px 10%" }}>
        <b>ENTER AI Research</b> - A research space, seeking ways to
        continuously improve expertise in the field, adopt state-of-the-art
        technologies and innovative approaches. We continually look for new
        methods and approaches to improve our products and services with AI
        technology. Our team cooperates with R&D departments of the leading
        companies in Machine Learning (ML), computer vision (CV) and natural
        language processing (NLP).
        <div className="p2">
          <h3>Contact details</h3>
          <ul>
            <li>Phone: 70755 85837</li>
            <li>Mobile: 97044 91986</li>
            <li>Email: admin@enterai.in</li>
            <li>
              <a
                href="https://www.linkedin.com/company/enter-ai/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ResearchHome;
