import React from "react";
// import "../../styles/About.scss";

const TermsAndConditions = () => {
  return (
    <div style={{ fontSize: "2.5vmin", margin: "20px 40px" }}>
      <b style={{ fontSize: "4vmin" }}>Terms and Conditions</b>
      <br />
      <br />
      (By placing the order / enrolling to courses, you have read and agree to
      enterai.in’s Terms of Use, Cancellation and Refund policy, and privacy
      policy.) This agreement (“user agreement) incorporates the terms and
      conditions for ENTERAI Software Technologies Pvt. Ltd. to provide services
      to the person (“the user”).
      <ol>
        <li>In case of cancellation, no refund will be provided.</li>
        <li>
          Sharing and buying of – Illegal Copies of our content whoever commits
          an offense shall be punishable as per appropriate sections with court
          of law.
        </li>
        <li>
          The unauthorized reproduction or distribution of this copyrighted work
          is illegal. Criminal copyright infringement is Punishable as per
          concerned act.
        </li>
        <li>Your order cannot be modified.</li>
        <li>
          You acknowledge and agree that Your user ID and password (“Participant
          Account”) is for Your exclusive use only.
        </li>
        <li>
          While You are granted a limited and non-exclusive right to use the
          Website, the Services, and the Content and Course-ware for the
          Restricted Purpose as set forth in this Agreement, You acknowledge and
          agree that We are the sole and exclusive owner of the Website, the
          Services and the Content and Course-ware and as such are vested with
          all Intellectual Property Rights and other proprietary rights in the
          Website, the Services, and the Content and Course-ware.
        </li>
        <li>
          You acknowledge and agree that this Agreement other than permitting
          You to use the Website, the Services, and the Content and Course-ware
          for the Restricted Purpose does not convey to You in any manner or
          form any right, title or interest of a proprietary, or any other
          nature in the Website, the Services, and the Content and Course-ware.
        </li>
        <li>
          Please note, enterai.in is not responsible for how others use
          information that is publicly available or otherwise accessible to
          others who have access to the Services.
        </li>
        <li>
          The validity of courses with more than 6 months duration is 1 year and
          for courses with less than 6 months duration is 6 months.
        </li>
        <li>
          Any intellectual property generated during the coursework by the
          instructor, employees and course participants also belong to ENTERAI
          Software Technologies Pvt. Ltd.
        </li>
        <li>
          The course content may not be reproduced
          /shared/translated/edited/derived-from without prior written
          permission from ENTERAI Software Technologies Pvt. Ltd. 12. All
          copyrights to any derivative work relating to this course belong to
          ENTERAI Software Technologies Pvt. Ltd.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
