import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { modules } from "../data/syllabus";
import colors from "../styles/_colors.scss";

const ModulesAccordion = (props) => {
  const { module } = props;
  const { id, name, syllabus } = module;
  return (
    <Accordion
      style={{
        borderRadius: "4px",
        overflow: "hidden",
        border: `1px solid ${colors.borderDefault}`,
      }}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        // sx={{ fontWeight: 800 }}
      >
        <Typography>
          Module {id}: {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          {syllabus?.map((item, index) => (
            <div key={index}>- {item}</div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const DataScienceCurriculum = () => {
  return (
    <React.Fragment>
      <div className="heading">Certified Data Science Course Curriculum</div>
      <div className="syllabus">
        <div>
          {modules.slice(0, 6).map((module) => {
            return <ModulesAccordion module={module} key={module.id} />;
          })}
        </div>
        <div>
          {modules.slice(6, 11).map((module) => {
            return <ModulesAccordion module={module} key={module.id} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataScienceCurriculum;
