import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import { useDispatch } from "react-redux";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Placements from "./pages/Placements";
import About from "./pages/About";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ELibrary from "./pages/ELibrary";
import Careers from "./pages/Careers";
import Forum from "./pages/Forum";
import CreateNewCourse from "./pages/CreateNewCourse";
import Profile from "./pages/Profile";
import Landing from "./pages/Landing";
import TechHome from "./pages/Tech/Home";
import TechAbout from "./pages/Tech/About";
import TermsAndConditions from "./pages/TermsConditions";
import ResearchHome from "./pages/Research/Home";
import { setUserDetails, setCourses } from "./redux/reducer";
import { allCourses } from "./axios";
import "./App.css";

const EduLayout = () => (
  <>
    <Navbar />
    <div className="appInnerCon">
      <Outlet />
    </div>
    <Footer />
  </>
);

const TechLayout = () => (
  <>
    <Navbar isTech={true} />
    <div className="appInnerCon">
      <Outlet />
    </div>
    {/* <Footer /> */}
  </>
);

const ResearchLayout = () => (
  <>
    <Navbar isResearch={true} />
    <div className="appInnerCon">
      <Outlet />
    </div>
    {/* <Footer /> */}
  </>
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    if (userDetails) {
      dispatch(setUserDetails(userDetails));
    }
    async function fetchCourses() {
      await allCourses()
        .then((res) => {
          dispatch(setCourses(res.data));
        })
        .catch((err) => console.log(err));
    }
    fetchCourses();
  }, [dispatch]);

  return (
    <div className="App">
      <SuperSEO
        title="ENTER AI"
        description="ENTER AI - Connect with Intelligence"
        lang="en"
      />
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/tech/" element={<TechLayout />}>
            <Route index element={<TechHome />} />
            <Route path="about" element={<TechAbout />} />
          </Route>
          <Route path="/research/" element={<ResearchLayout />}>
            <Route index element={<ResearchHome />} />
          </Route>
          <Route path="/edu/" element={<EduLayout />}>
            <Route index element={<Home />} />
            <Route path="courses" element={<Courses />} />
            <Route path="placements" element={<Placements />} />
            <Route path="about" element={<About />} />
            <Route path="login" element={<Login />} />
            <Route path="forum" element={<Forum />} />
            <Route path="careers" element={<Careers />} />
            <Route path="e-library">
              <Route index element={<ELibrary />} />
              <Route path=":id" element={<ELibrary />} />
            </Route>
            <Route path="register" element={<Register />} />
            <Route path="create-course" element={<CreateNewCourse />} />
            <Route path="profile" element={<Profile />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
