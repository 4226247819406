import TextField from "../components/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { Formik } from "formik";
import { newHireInquiry } from "../axios";

const ContactUsForm = ({
  setIsLoading,
  setAlertMessage,
  setAlertOpen,
  setAlertSeverity,
  title,
  subTitle,
}) => {
  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        phoneNumber: "",
        companyName: "",
        designation: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        name: Yup.string().max(255).required("Name is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        companyName: Yup.string().required("Company name is required"),
        designation: Yup.string().required("Designation is required"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading?.(true);
        setSubmitting(true);
        const requestBody = {
          name: values.name,
          work_email: values.email,
          phone_number: values.phoneNumber,
          company_name: values.companyName,
          designation: values.designation,
        };
        await newHireInquiry(requestBody)
          .then(() => {
            setAlertSeverity?.("success");
            setAlertMessage?.("Details submitted successfully");
            setAlertOpen?.(true);
          })
          .catch(() => {
            setAlertSeverity?.("success");
            setAlertMessage?.("We have already received your response");
            setAlertOpen?.(true);
          });
        setIsLoading?.(false);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        // const submitBtnDisabled = Object.keys(errors).length > 0;
        return (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <div
              className="form"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "2.3vmin",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                {title}
              </div>
              <div style={{ textAlign: "center" }}>{subTitle}</div>
              <TextField
                label="Name"
                name="name"
                required
                fullWidth
                type="text"
                innerStyle={{ height: "40px" }}
                inputStyle={{ lineHeight: "30px" }}
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <TextField
                label="Work Email"
                type="email"
                fullWidth
                name="email"
                required
                innerStyle={{ height: "40px" }}
                inputStyle={{ lineHeight: "30px" }}
                helperText={touched.email && errors.email}
                error={Boolean(touched.email && errors.email)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                name="phoneNumber"
                fullWidth
                required
                innerStyle={{ height: "40px" }}
                inputStyle={{ lineHeight: "30px" }}
                helperText={touched.phoneNumber && errors.phoneNumber}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
              />
              <TextField
                label="Company Name"
                name="companyName"
                fullWidth
                required
                innerStyle={{ height: "40px" }}
                inputStyle={{ lineHeight: "30px" }}
                helperText={touched.companyName && errors.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
              />
              <TextField
                label="Designation"
                name="designation"
                fullWidth
                required
                innerStyle={{ height: "40px" }}
                inputStyle={{ lineHeight: "30px" }}
                helperText={touched.designation && errors.designation}
                error={Boolean(touched.designation && errors.designation)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.designation}
              />
              <Button
                type="submit"
                variant="contained"
                style={{ textTransform: "none", fontWeight: "bold" }}
              >
                Submit
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactUsForm;
