import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footerLeftContainer">
        <div>&copy; 2022 ENTER AI</div>
        <div onClick={() => navigate("/edu/terms-and-conditions")}>
          Terms of use
        </div>
        <div>Privacy Policy</div>
        <div>Cookie Notice</div>
      </div>
      <div className="footerRightContainer">
        <div>
          <img
            src="https://img.icons8.com/fluency/48/000000/facebook-new.png"
            alt="facebook"
            height="30"
          />
        </div>
        <div>
          <img
            src="https://img.icons8.com/color/48/000000/twitter--v1.png"
            alt="twitter"
            height="30"
          />
        </div>
        <div>
          <img
            src="https://img.icons8.com/fluency/48/000000/instagram-new.png"
            alt="instagram"
            height="30"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
