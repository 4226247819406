import React from "react";
import ChangePassword from "../components/ChangePassword";

const Profile = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
        margin: "5%",
        minWidth: "250px",
        width: "40vw",
        maxWidth: "500px",
      }}
    >
      <div style={{ fontSize: "3vmin", fontWeight: 500 }}>Change Password</div>
      <ChangePassword />
    </div>
  );
};

export default Profile;
