import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "../components/TextField";
import * as Yup from "yup";
import { Formik } from "formik";
import CareersBG from "../assets/hiring_careers.svg";
import { newWorkInquiry } from "../axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Loader from "../components/Loader";
import "../styles/Careers.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Careers = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {isLoading && <Loader />}

      <div className="careerPage">
        <img src={CareersBG} alt="careers" />
        <Formik
          initialValues={{
            email: "",
            name: "",
            phoneNumber: "",
            resume: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            name: Yup.string().max(255).required("Name is required"),
            phoneNumber: Yup.string().required("Phone number is required"),
            resume: Yup.string().required("Resume is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setIsLoading(true);
            setSubmitting(true);
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("phone_number", values.phoneNumber);
            formData.append("resume", values.resume);
            await newWorkInquiry(formData)
              .then(() => {
                setAlertSeverity("success");
                setAlertMessage("Details submitted successfully");
                setAlertOpen(true);
              })
              .catch(() => {
                setAlertSeverity("success");
                setAlertMessage("We have already received your response");
                setAlertOpen(true);
              });
            setIsLoading(false);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => {
            // const submitBtnDisabled = Object.keys(errors).length > 0;
            return (
              <form onSubmit={handleSubmit} className="careerForm">
                <h1 style={{ fontSize: "3.5vmin" }}>
                  Are you interested to join our team?
                </h1>
                <div
                  className="form"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  <TextField
                    label="Name"
                    name="name"
                    required
                    fullWidth
                    type="text"
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    name="email"
                    required
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <TextField
                    label="Mobile Number"
                    type="tel"
                    name="phoneNumber"
                    fullWidth
                    required
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  <TextField
                    label="Upload CV/Resume"
                    name="resume"
                    type="file"
                    fullWidth
                    required
                    innerStyle={{ height: "40px" }}
                    inputStyle={{ lineHeight: "30px" }}
                    helperText={touched.resume && errors.resume}
                    error={Boolean(touched.resume && errors.resume)}
                    onChange={(e) => setFieldValue("resume", e.target.files[0])}
                    onBlur={handleBlur}
                    // value={values.resume.}
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    style={{ textTransform: "none", fontWeight: "bold" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Careers;
